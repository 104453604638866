import { Controls } from '../ExtraControls/ExtraControls';

/**
 * @todo Go over this data and make sure that all keys are relevant
 */
export const initialCarLoanData = {
  [Controls.Insurance]: true,
  [Controls.BlippInsurance]: true,
  [Controls.Delivery]: false,
  investment_percent: 20,
  'total-cost': 120000,
  'total-month-cost': undefined,
  'campaign-code': '',
  duration: 'three-years',
  residual_percent: 55,
  ssn: '',
  email: '',
  phone: '',
  terms: false,
  'general-error': '',
};
