import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { List } from 'components/List';
import { ListItemSize } from 'components/List/ListItem';
import { Colors } from 'enums/Colors';
import { ICoupon } from 'types/CouponCode';
import { getFormattedPrice } from 'utils/getFormattedPrice';
// import { getFormattedNumber } from 'utils/getFormattedNumber';
import { calculateLoanAmount } from 'lib/loanCalculator/calculateLoanAmount';
import { LoanType } from 'enums/LoanType';
import { BLIPP_FEE_PERCENT } from 'content/globalConstants';

import { INTEREST_DEFAULT, calculateDownpaymentFromPercent } from '../utils/calculateMonthlyCost';
import { getMonths } from '../utils/getResidualPercent';

import styles from './LoanSummary.module.scss';

const getItems = (
  interestRate: number,
  total: number,
  investment: number,
  totalMonthCost: number,
  displayTotalMonth: number,
  months: number,
  warrantyIsChecked: boolean,
  loanType: LoanType,
  residual: number
) => [
  /* {
    size: ListItemSize.Small,
    label: 'Ränta',
    value: `${getFormattedNumber(interestRate * 100)} %`,
  }, */
  {
    size: ListItemSize.Small,
    label: 'Blipp avgift',
    value: `${(total * BLIPP_FEE_PERCENT) / 100} kr`,
  },
  {
    size: ListItemSize.Small,
    label: 'Kontantinsats',
    value: investment ? `${getFormattedPrice(investment)}` : '',
    tooltip: (
      <>
        <h4>Kontantinsats</h4>
        <p>
          När du köper en bil med blipps billån behöver du alltid lägga minst 20% i kontantinsats.
        </p>
        <p>
          Banken kräver att man betalar en liten del kontant för att risken inte ska bli för stor.
          Det hjälper dig också att att få en lägre månadskostnad under din avbetalningsperiod. Du
          kan alltid lägga en större summa än 20% i kontantinsats om du skulle vilja det. Då har du
          möjlighet till ännu längre månadskostnad.
        </p>
      </>
    ),
  },
  {
    size: ListItemSize.Small,
    label: loanType !== LoanType.Ecster ? 'Restvärde' : '',
    value: loanType !== LoanType.Ecster ? `${residual}%` : '',
    tooltip:
      loanType !== LoanType.Ecster ? (
        <>
          <h4>Restvärde</h4>
          <p>
            Restvärde är det du har kvar att betala på bilen till finansbolaget när din låneperiod
            är över. Du kan alltid välja att förlänga ditt lån efter din låneperiod.
          </p>
        </>
      ) : (
        ''
      ),
  },
  {
    size: ListItemSize.Small,
    label: 'Lånesumma',
    value: investment
      ? `${getFormattedPrice(
          calculateLoanAmount(
            total * (1 + BLIPP_FEE_PERCENT / 100),
            investment,
            warrantyIsChecked,
            3995
          )
        )}`
      : '',
  },
  {
    label: 'Total månadskostnad',
    value: totalMonthCost
      ? `${displayTotalMonth ? getFormattedPrice(displayTotalMonth) : ''}/mån`
      : '',
  },
];

interface IProps {
  total: number;
  totalWithFees: number;
  isInverted?: boolean;
  loanType: LoanType;
}

export const LoanSummary: React.FC<IProps> = ({
  total,
  totalWithFees,
  isInverted = false,
  loanType,
}) => {
  const [interest, setInterest] = useState(INTEREST_DEFAULT);
  const [displayTotalMonth, setDisplayTotalMonth] = useState(0);

  const { watch } = useFormContext();
  const investmentPercent = watch('investment_percent');
  const totalMonthCost = watch('total-month-cost');
  const duration = watch('duration');
  const warrantyIsChecked = watch('blippinsurance');
  const residual = watch('residual_percent');

  const campaignCodeData: ICoupon = watch('campaign-code-data');

  useEffect(() => {
    setDisplayTotalMonth(totalMonthCost);
  }, [totalMonthCost]);

  useEffect(() => {
    if (!campaignCodeData) return;

    setInterest(INTEREST_DEFAULT - campaignCodeData.percentReduction.value);
  }, [campaignCodeData]);

  const items = getItems(
    interest,
    total,
    calculateDownpaymentFromPercent(totalWithFees, investmentPercent),
    totalMonthCost,
    displayTotalMonth,
    getMonths(duration),
    warrantyIsChecked,
    loanType,
    residual
  );

  return (
    <div className={styles['loan-summary']}>
      {isInverted ? (
        <List
          bgColor={Colors.Transparent}
          listItemLabelColor={Colors.White}
          listItemValueColor={Colors.White}
          items={items}
        />
      ) : (
        <List items={items} />
      )}
    </div>
  );
};
